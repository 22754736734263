import * as React from "react";
import { UAParser } from "ua-parser-js";
import logo from "./logo.svg";
import download from "./app-store-download.svg";

import "./Welcome.css";

enum Platform {
  iOS = 1,
  Android = 2,
  Unknown = 3
}

const IOSLink = () => {
  return (
    <a href="https://itunes.apple.com/app/id1357962559">
      <img
        src={download}
        alt="Apple logo"
        style={{ width: "80%", maxWidth: "160px" }}
      />
    </a>
  );
};

const AndroidLink = () => {
  return (
    <a href="http://play.google.com/store/apps/details?id=io.arke.mobile">
      <img
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
        style={{ width: "100%", maxWidth: "180px" }}
      />
    </a>
  );
};

class BrandedAppButton extends React.Component<{}, {}> {
  private getPlatform() {
    const agent = new UAParser(window.navigator.userAgent);
    const os = agent.getOS();

    if (os.name === "iOS") {
      return Platform.iOS;
    } else if (os.name === "Android") {
      return Platform.Android;
    } else {
      return Platform.Unknown;
    }
  }

  public render() {
    const platform = this.getPlatform();
    switch (platform) {
      case Platform.iOS:
        return <IOSLink />;
      case Platform.Android:
        return <AndroidLink />;
      default:
        return (
          <>
            <IOSLink />
            <AndroidLink />
          </>
        );
    }
  }
}

export default class Welcome extends React.Component<{}, {}> {
  public render() {
    return (
      <div className="Welcome-wrapper">
        <img src={logo} className="Logo" alt="logo" />
        <div className="content">
          <div className="Welcome-heading">
            <h2>Welcome to Arke</h2>
            <p>Make your guests welcome.</p>
          </div>
          <div className="Welcome-actions">
            <BrandedAppButton />
            <a
              href="https://app.arke.io"
              style={{ marginTop: "20px", color: "#ffffff", border: "0px" }}
            >
              Log in to portal
            </a>
          </div>
        </div>
      </div>
    );
  }
}

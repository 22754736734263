import { computed, observable } from "mobx";
class AppState {
  @observable public online: boolean = true;
  @observable public offline: boolean = false;
  @observable public hasAccess: boolean = true;
  @computed
  public get canRun(): boolean {
    return this.hasAccess && this.online;
  }
  @observable public updateAvailable: boolean = false;
  public setOnline() {
    this.offline = false;
    this.online = true;
  }
  public setOffline() {
    this.online = false;
    this.offline = true;
  }
  public setAccess(hasAccess: boolean): void {
    this.hasAccess = hasAccess;
  }
}

const state = new AppState();

window.addEventListener("offline", () => {
  state.setOffline();
});
window.addEventListener("online", () => {
  state.setOnline();
});
export default state;

import * as React from "react";
import * as ReactDOM from "react-dom";
import unregiserServiceWorker from "./registerServiceWorker";
// tslint:disable-next-line:no-var-requires
import { Route, Router } from "react-router-dom";
import ErrorView from "./ErrorView";
import "./index.css";
import Welcome from "./Welcome";
import history from "./history";

class AppRouter extends React.Component<{}, {}> {
  public render() {
    return (
      <div>
        <Router history={history}>
          <div>
            <div className="wrapper">
              <Route path="/" exact={true} component={Welcome} />
              <Route path="/error" component={ErrorView} />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}
ReactDOM.render(<AppRouter />, document.getElementById("root") as HTMLElement);
unregiserServiceWorker();

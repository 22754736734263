import * as React from "react";
export default class ErrorView extends React.Component<
  {
    // tslint:disable-next-line:no-any
    location: any;
    // tslint:disable-next-line:no-any
    match: any;
    // tslint:disable-next-line:no-any
    history: any;
  },
  {}
> {
  public render() {
    return (
      <>
        <p>
          An error occured. Please try again later.
          <div
            style={{ textAlign: "left", color: "red" }}
            className="Form-Margin"
          >
            {this.props.location.state !== undefined
              ? this.props.location.state.errorMessage
              : ""}
          </div>
        </p>
      </>
    );
  }
}
